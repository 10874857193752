import { Injectable } from '@angular/core';
import { DownloadCenterApiWrapper } from '../classes/download-center-api-wrapper';
import { ApiService, CommonService, LoadingSpinnerService, PhxLocalizationService } from '../../common';
import { merge, Observable, retry, Subject, throwError, timer } from 'rxjs';
import {
  DownloadCenterFileStatus,
  IDownloadCenterDateOrganizedFiles,
  IDownloadCenterDateOrganizedFilesList,
  IDownloadCenterFile,
  IDownloadCenterFiles,
  IReportPermissions
} from '../model/download-center';
import { catchError, delay, distinctUntilChanged, map, repeatWhen, take, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DownloadCenterButtonService } from '../download-center-button/services/download-center-button.service';
import { Router } from '@angular/router';
import { DOWNLOAD_CENTER_SERVICE_FILE_STATUS_DETECTION_DELAY_MS, DOWNLOAD_CENTER_SERVICE_FILE_STATUS_DETECTION_REFRESH_RATE_MS } from '../config';
import { DownloadCentreModuleResourceKeys } from '../model/download-centre-module-resource-keys';
import { ToastService } from '../../common/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadCenterService {
  dlCenterApiObj: DownloadCenterApiWrapper;
  downloadCentreModuleResourceKeys = DownloadCentreModuleResourceKeys;

  constructor(loadingSpinnerService: LoadingSpinnerService,
              private commonService: CommonService,
              private router: Router,
              private httpClient: HttpClient,
              private apiService: ApiService,
              private toastService: ToastService,
              private downloadCenterButtonService: DownloadCenterButtonService,
              private localizationService: PhxLocalizationService) {
    this.dlCenterApiObj = new DownloadCenterApiWrapper(apiService, httpClient, loadingSpinnerService);
  }

  getDescSortedDownloadCenterFiles(): Observable<IDownloadCenterDateOrganizedFilesList> {
    return this.dlCenterApiObj.getDownloadCenterFiles().pipe(
      map((downloadCenterFiles: IDownloadCenterFiles) => {
        const dateOrganizedFilesList: IDownloadCenterDateOrganizedFilesList = [];
        if (downloadCenterFiles.length === 0) {
          return dateOrganizedFilesList;
        }

        // Sort files by ExportRequestedDate
        downloadCenterFiles.sort((f1, f2) => {
          return (f2.ExportRequestedDate.valueOf()) - (f1.ExportRequestedDate.valueOf());
        });


        // Create IDownloadCenterDateOrganizedFiles objects for files
        // Preserve sort order from above.
        // More recent IDownloadCenterDateOrganizedFiles objects appear first
        //   Within an IDownloadCenterDateOrganizedFiles we have a file list. More recent files appear first.

        // Initialize the object for the first date
        let currentDateOrganizedFiles: IDownloadCenterDateOrganizedFiles = {
          Date: new Date(downloadCenterFiles[0].ExportRequestedDate),
          DownloadCenterFiles: []
        };
        currentDateOrganizedFiles.Date.setHours(0, 0, 0, 0);
        dateOrganizedFilesList.push(currentDateOrganizedFiles);

        downloadCenterFiles.forEach(dcFile => {
          const fileDate = new Date(dcFile.ExportRequestedDate);
          fileDate.setHours(0, 0, 0, 0);

          if (fileDate.getTime() !== currentDateOrganizedFiles.Date.getTime()) {
            // We've entered a new date
            currentDateOrganizedFiles = {
              Date: fileDate,
              DownloadCenterFiles: []
            };
            dateOrganizedFilesList.push(currentDateOrganizedFiles);
          }

          currentDateOrganizedFiles.DownloadCenterFiles.push(dcFile);
        });

        return dateOrganizedFilesList;
      })
    );
  }

  downloadFile(downloadCenterFile: IDownloadCenterFile) {
    return this.dlCenterApiObj.downloadFile(downloadCenterFile).pipe(
      catchError(err => {
        const msg = this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.downloadFailedError);
        this.toastService.logError(msg);
        return throwError(err);
      })
    ).subscribe(res => {
      this.commonService.saveReportExportFileFromHttpResponse(res);
    });
  }

  clearFile(downloadCenterFile: IDownloadCenterFile) {
    return this.dlCenterApiObj.clearFile(downloadCenterFile).pipe(
      take(1),
      catchError(err => {
        const msg = this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.clearFileError);
        this.toastService.logError(msg);
        return throwError(err);
      })
    ).subscribe();
  }

  clearAllFiles() {
    return this.dlCenterApiObj.clearAllFiles().pipe(
      take(1),
      catchError(err => {
        const msg = this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.clearFilesError);
        this.toastService.logError(msg);
        return throwError(err);
      })
    ).subscribe();
  }

  retryFilePreparation(downloadCenterFile: IDownloadCenterFile) {
    const msg = this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.preparingForExportMessage);
    this.toastService.showToast(msg, 'notification', 'success');
    this.dlCenterApiObj.retryFilePreparation(downloadCenterFile)
      .pipe(take(1)).subscribe(() => {
      this.detectFileReadiness(downloadCenterFile.Id);
    });
  }

  getReportPermissions(): Observable<IReportPermissions> {
    return this.dlCenterApiObj.getReportPermissions();
  }

  detectFileReadiness(reportExportId: string) {
    const reportChanged = new Subject();
    return this.dlCenterApiObj.getReportExport(reportExportId)
      .pipe(
        map((dcFile: IDownloadCenterFile) => {
          return { status: dcFile.Status, exportedCount: dcFile.ExportedCount };
        }),
        distinctUntilChanged(),
        repeatWhen(completed => merge(
          timer(DOWNLOAD_CENTER_SERVICE_FILE_STATUS_DETECTION_DELAY_MS),
          completed.pipe(delay(DOWNLOAD_CENTER_SERVICE_FILE_STATUS_DETECTION_REFRESH_RATE_MS))
        )),
        retry({
          delay: () => timer(DOWNLOAD_CENTER_SERVICE_FILE_STATUS_DETECTION_REFRESH_RATE_MS)
        }),
        takeUntil(reportChanged))
      .subscribe(dcFileInfo => {
        switch (dcFileInfo.status) {
          case DownloadCenterFileStatus.Preparing:
            return;
          case DownloadCenterFileStatus.Ready:
            if ((dcFileInfo.exportedCount || 0) === 0) {
              this.toastService.showToast(this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.noResultsExport), 'error', 'error', 6000, 'view')
                .onAction()
                .subscribe(() => {
                  this.router.navigate(['/next/download-center']);
                });
            } else {
              this.toastService.showToast(this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.reportReadyToDownload), 'get_app', 'success', 6000, 'view')
                .onAction()
                .subscribe(() => {
                  this.router.navigate(['/next/download-center']);
                });
            }
            this.downloadCenterButtonService.notifyDownloadsChanged();
            break;
          case DownloadCenterFileStatus.Error:
            this.toastService.showToast(this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.unableToExportReport), 'error', 'error', 6000, 'view')
              .onAction()
              .subscribe(() => {
                this.router.navigate(['/next/download-center']);
              });
            this.downloadCenterButtonService.notifyDownloadsChanged();
            break;
          case DownloadCenterFileStatus.Archived:
            this.toastService.logInfo(this.localizationService.translate(this.downloadCentreModuleResourceKeys.report.common.exportErrorArchived));
            break;
        }
        reportChanged.next(true);
        reportChanged.complete();
        this.dlCenterApiObj.forceFileLoad();
      });
  }
}
