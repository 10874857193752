
import {from as observableFrom,  Observable } from 'rxjs';
import { CommandResponse } from './../model/command-response';
import { ApiService } from './api.service';
import { EntityList } from './../model/entity-list';
import { Injectable } from '@angular/core';
import { PhxDocument } from './../model/phx-document';
import { PhxConstants } from '../PhoenixCommon.module';
import { CommonService } from './common.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WindowRefService } from './WindowRef.service';
import { saveAs } from 'file-saver';

declare const oreq: any;

@Injectable()
export class DocumentService {

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private winRefService: WindowRefService
  ) { }

  public getEntityDocumentsList(entityTypeId: number, entityId: number, tableState, oDataParams): Observable<any> {
    const tableStateParams = tableState && tableState !== undefined ? this.generateRequestObject(tableState).url() : '';
    return observableFrom(this.apiService.queryWithPromise(`document/${entityTypeId}/${entityId}?` + (oDataParams && oDataParams !== undefined ? oDataParams + '&' : '') + tableStateParams));
  }

  generateRequestObject(tableState) {
    const searchObj = tableState?.search?.predicateObject ? tableState.search.predicateObject : null;
    const sortObj = tableState?.sort?.predicate ? tableState.sort.predicate + (tableState.sort.reverse ? ' desc ' : '') : null;
    let currentPage = tableState?.pagination?.currentPage ? tableState.pagination.currentPage : 1;
    const pageSize = tableState?.pagination?.pageSize ? tableState.pagination.pageSize : 30;
    currentPage--;
    let oDataParams = oreq.request();
    if (Object.keys(searchObj).length > 0) {
      oDataParams = oDataParams.withFilter(oreq.filter().smartTableObjectConverter(searchObj));
    }
    if (sortObj) {
      oDataParams = oDataParams.withOrderby(sortObj);
    }
    if (!(tableState?.pagination && tableState.pagination.isDisabled === true)) {
      oDataParams = oDataParams
        .withTop(pageSize)
        .withSkip(currentPage * pageSize)
        .withInlineCount();
    } else {
      oDataParams = oDataParams.withInlineCount();
    }
    return oDataParams;
  }

  // publicId is Guid
  public getDocumentById(publicId: string): Promise<PhxDocument> {
    return this.apiService.queryWithPromise(`document/${publicId}`);
  }

  public getEntityDocuments(entityTypeId: number, entityId: number): Promise<EntityList<PhxDocument>> {
    return this.apiService.queryWithPromise(`document/${entityTypeId}/${entityId}`);
  }

  public getEntityDocumentsByDocumentTypeId(entityTypeId: number, entityId: number, documentTypeId: PhxConstants.DocumentType, showLoader: boolean = true): Promise<EntityList<PhxDocument>> {
    return this.apiService.queryWithPromise(`document/${entityTypeId}/${entityId}/${documentTypeId}`, showLoader);
  }

  public downloadDocumentById(publicId: string): void {
    this.apiService.getBlob<Blob>(`document/${publicId}/getCsvStreamByPublicId`)
      .subscribe({
        next: response => {
          const fileData = response.body;
          let fileName = 'report.csv';
          const contentDisposition = response.headers.get('Content-Disposition');
          if (contentDisposition) {
            const fileNameHeader = contentDisposition.split(';')[1];
            if (fileNameHeader) {
              fileName = fileNameHeader.split('=')[1].slice(1, -1) || fileName;
            }
          }
          const fileBlob = new Blob([fileData as BlobPart], { type: 'text/csv' });
          saveAs(fileBlob, fileName);
        },
        error: () => {
          this.commonService.logError('Document download has failed.');
        }
      });
  }

  public deleteDocumentByPublicId(publicId: string): Promise<CommandResponse> {
    return this.apiService.command('RemoveDocument',
      {
        PublicId: publicId,
        IncludeChildren: true,
        WorkflowPendingTaskId: -1
      });
  }

  public createThumbnailDocumentLink(publicId: string, width: number = 110, height: number = 110): string {
    const docEndpoint: string = 'api/document';
    const docAction: string = 'thumbnail';
    return `${this.commonService.api2Url}${docEndpoint}/${publicId}/${docAction}?width=${width}&height=${height}&access_token=${this.commonService.bearerToken()}`;
  }

  public createSanitizedThumbnailDocumentLink(publicId: string, width: number = 110, height: number = 110): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.createThumbnailDocumentLink(publicId, width, height));
  }

  public getProfilePicture(profileId: number): Promise<string> {
    const imageUrl = this.apiService.queryWithPromise(`UserProfile/GetContactId/${profileId}`, false)
      .then((contactId: number) => {
        return this.getEntityDocumentsByDocumentTypeId(PhxConstants.EntityType.Contact, contactId, PhxConstants.DocumentType.Profile, false);
      })
      .then((docList: EntityList<PhxDocument>) => {
        if (docList.Items.length !== 0) {
          const publicId = docList.Items[0].PublicId;
          return this.createThumbnailDocumentLink(publicId, 120, 120);
        } else {
          return Promise.resolve(null);
        }
      });
    return imageUrl;
  }

  public openDocument(publicId: string, pdf: boolean = true) {

    let redirectWindow: Window = null;

    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    const isSafariNonDesktop = navigator.vendor?.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS') && !!navigator.userAgent.match(/(iPad|iPhone)/);
    if (isSafariNonDesktop) {
      redirectWindow = this.winRefService.openUrl('about:blank', '_blank');
    }

    this.getDocumentUrl(publicId, pdf).then(url => {
      if (redirectWindow) {
        redirectWindow.location.href = url;
      } else {
        this.winRefService.openUrl(url, '_blank');
      }
    }).catch(() => {
      // error UX
    });
  }

  public async getDocumentUrlForIframe(publicId: string, pdf: boolean = true): Promise<SafeResourceUrl> {
    const url = await this.getDocumentUrl(publicId, pdf);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private getDocumentUrl(publicId: string, pdf: boolean = true): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apiService.queryWithPromise(`report/getDownloadAccess/${publicId}?pdf=${pdf}`, false)
      .then((encryptedTicket) => {
        if (encryptedTicket) {
          resolve(`${this.commonService.api2Url}api/report/download?tx=${encryptedTicket}`);
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      });
    });
  }

}
