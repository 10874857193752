import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CodeValueGroups, PhxConstants } from '../model';
import { IReportShareDetails } from '../model/data-table/i-report-share-details';
import { ReportShareApiWrapper } from '../../helpers/classes/report-sharing-api/report-share-api-wrapper';
import { IReportShareDetailSave, IReportSharee } from '../../helpers/classes/report-sharing-api/i-report-sharing-api';
import { CodeValueService } from './code-value.service';
import { CommonListsObservableService } from '../lists/lists.observable.service';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ReportShareService {
  reportSharingApiWrapper: ReportShareApiWrapper;
  shareeList$ = new BehaviorSubject<IReportSharee[]>(null);
  shareeMap$ = new BehaviorSubject<Map<string | number, IReportSharee>>(null);
  fullShareeList$ = new BehaviorSubject<IReportSharee[]>(null);
  fullShareeMap$ = new BehaviorSubject<Map<string | number, IReportSharee>>(null);
  private internalFunctionalRoles: Map<number, string>;

  constructor(private codeValueService: CodeValueService,
              private commonListsObservableService: CommonListsObservableService,
              private toastService: ToastService,
              authService: AuthService,
              apiService: ApiService) {
    this.reportSharingApiWrapper = new ReportShareApiWrapper(codeValueService, authService, apiService, this.commonListsObservableService);
    this.setShareeObservables();
  }

  get functionalRoles(): Map<number, string> {
    if (!this.internalFunctionalRoles) {
      this.internalFunctionalRoles = new Map<number, string>();
      this.codeValueService.getCodeValues(CodeValueGroups.FunctionalRole, false).map(funcRoleObj => {
        this.internalFunctionalRoles.set(funcRoleObj.id, funcRoleObj.text);
      });
    }
    return this.internalFunctionalRoles;
  }

  getFunctionRoleString(functionalRoleIds: number[]): string {
    return (functionalRoleIds || [])
      .map(functionalRoleId => this.functionalRoles.get(functionalRoleId))
      .filter(functionRoleString => !!functionRoleString)
      .join(', ');
  }

  public getAllReportsForCurrentUser(showSpinner = false) {
    return this.reportSharingApiWrapper.getAllReportsForCurrentUser(showSpinner);
  }

  public getAllReportsForCurrentUserByReportType(reportTypeId: PhxConstants.ReportType, showSpinner = false) {
    return this.reportSharingApiWrapper.getAllReportsForCurrentUserByReportType(reportTypeId, showSpinner);
  }

  public getReportShareById(reportId: number, showSpinner = false) {
    return this.reportSharingApiWrapper.getReportShareDetail(reportId);
  }

  public saveReportShare(reportShareDetail: IReportShareDetails) {
    return this.reportSharingApiWrapper.postReportShareShareChanges(reportShareDetail, true)
      .pipe(catchError(err => {
        this.toastService.showToast('Failed to update report share. Please try again', 'error', 'error');
        return throwError(err);
      }), map(response => {
        this.toastService.showToast('Report share updated', 'group', 'success');
        return response;
      }));
  }

  public saveReportShareDetail(reportShareDetailSave: IReportShareDetailSave, showSpinner = false) {
    return this.reportSharingApiWrapper.postReportShareFullChanges(reportShareDetailSave, showSpinner);
  }

  public deleteReportShare(reportShareId: number, showSpinner = false) {
    const showErrorToast = () => this.toastService.showToast('Failed to delete report. Please try again', 'error', 'error');
    return this.reportSharingApiWrapper.deleteReportShare(reportShareId, showSpinner)
      .pipe(catchError(err => {
        showErrorToast();
        return throwError(err);
      }), map(changesOccurred => {
        if (changesOccurred) {
          this.toastService.showToast('Report Deleted', 'delete', 'success');
        } else {
          showErrorToast();
        }
        return changesOccurred;
      }));
  }

  private setShareeObservables() {
    this.reportSharingApiWrapper.getNewShareeData()
      .pipe(take(1))
      .subscribe((shareeData) => {
        this.shareeList$.next(shareeData.List);
        this.shareeMap$.next(shareeData.Map);
      });
    this.reportSharingApiWrapper.getFullShareeData()
      .pipe(take(1))
      .subscribe((shareeData) => {
        this.fullShareeList$.next(shareeData.List);
        this.fullShareeMap$.next(shareeData.Map);
      });
  }
}
