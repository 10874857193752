import { Injectable } from '@angular/core';
import { SignalrService } from './services/signalr.service';
import { PhxLocalizationService } from './services/phx-localization.service';
import { DialogService } from '.';
import { CommonService } from './services/common.service';
import * as _ from 'lodash';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';
import { EventService } from './services/event.service';
import { CommonDataService } from './services/commonData.service';

@Injectable()
export class RootService {

  constructor(
    private signalrSvc: SignalrService,
    private apiSvc: ApiService,
    private localizationSvc: PhxLocalizationService,
    private dialogSvc: DialogService,
    private commonSvc: CommonService,
    private router: Router,
    private eventSvc: EventService,
    private commonDataSvc: CommonDataService
  ) {
  }

  init() {
    this.apiSvc.onPrivate('HandlerExecuteException', (event, data) => {
      this.dialogSvc.notify(
        this.localizationSvc.translate('common.generic.handlerExecuteExceptionTitle'),
          '<strong>' + this.localizationSvc.translate('common.generic.handlerExecuteExceptionMessage') + '</strong><br/><br/>' + data.Message,
          {
            backdrop: 'static'
          });
    }, true)
      .then(() => {
      });

    this.signalrSvc.onPublic('WorkflowProcess', (event, data) => {
      this.eventSvc.trigger('broadcastEvent:WorkflowProcess', data);
    }, true)
      .then(() => {
      });


    // TODO: Remove or move to Contact component?
    this.apiSvc.onPrivate('BulkRegistrationInviteEvent', (event, data) => {
        if (data.LogWarning !== null && data.LogWarning.length > 0) {
          this.commonSvc.logWarning(data.LogWarning);
        }
        if (data.LogSuccess !== null && data.LogSuccess.length > 0) {
          this.commonSvc.logSuccess(data.LogSuccess);
        }
      },
      true
    );

    this.eventSvc.subscribe('broadcastEvent:WorkflowProcess', data => {
      const watchConfigOnWorkflowEvent = this.commonDataSvc.getWatchConfigOnWorkflowEvent();
      if (data.IsOwner) {
        if (
          watchConfigOnWorkflowEvent.stateNameGo.length > 0 &&
          watchConfigOnWorkflowEvent.groupingEntityTypeId > 0 &&
          watchConfigOnWorkflowEvent.targetEntityTypeId &&
          watchConfigOnWorkflowEvent.targetEntityId &&
          watchConfigOnWorkflowEvent.groupingEntityTypeId === data.GroupingEntityTypeId &&
          watchConfigOnWorkflowEvent.targetEntityTypeId === data.TargetEntityTypeId &&
          watchConfigOnWorkflowEvent.targetEntityId === data.TargetEntityId
        ) {
          if (this.router.url.includes(watchConfigOnWorkflowEvent.stateIncludesFilter)) {
            if (watchConfigOnWorkflowEvent.functionCallBack && typeof watchConfigOnWorkflowEvent.functionCallBack === 'function') {
              watchConfigOnWorkflowEvent.functionCallBack(data);
            } else {
              this.router.navigate([watchConfigOnWorkflowEvent.stateNameGo, watchConfigOnWorkflowEvent.stateParamMapping]);
            }
          }
        }
      }
    });
  }
}
