export const environment = {
  production: true,
  isProduction: '#{isProduction}',
  allowUnfinishedFeatures: '#{allowUnfinishedFeatures}',
  bubbleDataChartsUri: '#{bubbleDataChartsUri}',
  apiUrl: '#{apiUrl}',
  securityServiceApiEndPoint: '#{securityServiceApiEndPoint}',
  reportServiceApiEndpoint: '#{reportServiceApiEndpoint}',
  auditServiceApiEndPoint: '#{auditServiceApiEndpoint}',
  vmsTimesheetServiceApiEndpoint: '#{vmsTimesheetServiceApiEndpoint}',
  organizationServiceApiEndpoint: '#{organizationServiceApiEndpoint}',
  peopleServiceApiEndpoint: '#{peopleServiceApiEndpoint}',
  payrollServiceApiEndpoint: '#{payrollServiceApiEndpoint}',
  onboardingServiceApiEndpoint: '#{onboardingServiceApiEndpoint}',
  fboOnboardingServiceApiEndpoint: '#{fboOnboardingServiceApiEndpoint}',
  ruleEngineApiEndpoint: '#{ruleEngineApiEndpoint}',
  complianceServiceApiEndpoint: '#{complianceServiceApiEndpoint}',
  generalServiceApiEndpoint: '#{generalServiceApiEndpoint}',
  adminServiceApiEndpoint: '#{adminServiceApiEndpoint}',
  workOrderServiceApiEndpoint: '#{workOrderServiceApiEndpoint}',
  showComplianceComponent: '#{showComplianceComponent}',
  rollbar: {
    accessToken: '#{Rollbar.FrontEndAccessToken}',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: '#{Rollbar.Environment}'
    },
    enabled: '#{Rollbar.FrontEndEnabled}'
  },
  gaTrackingId: '#{gaTrackingId}',
  googleTagManagerId: '#{googleTagManagerId}',
  fullStoryId: '#{fullStoryId}',
  allowDuplicateSIN: '#{allowDuplicateSIN}',
  pageLoader: {
    apiUrl: '#{pageLoader.apiUrl}',
    allowLogging: '#{pageLoader.allowLogging}'
  },
  activeDirectoryRedirectUri: '#{ClientAppUrl}/',
  activeDirectoryPostLogoutRedirectUri: '#{ClientAppUrl}',
  azSearchAppInsightsInstrumentationKey: '#{azSearchAppInsightsInstrumentationKey}',
  contractorPortalLoginUrl: '#{contractorPortalLoginUrl}',
  isPortalMigrationFeatureEnable: '#{isPortalMigrationFeatureEnable}',
  inviteFBOUserToContractorPortal: '#{InviteFBOUserToContractorPortal}',
  sendInvitationReminderEnabled: '#{sendInvitationReminderEnabled}',
  enabledOBRClientCompanyIds: '#{enabledOBRClientCompanyIds}',
  instantRefreshChecklist: '#{instantRefreshChecklist}',
  userPilotToken: '#{userPilotToken}',
  enableQuickAddCanadianIncAddProfile: true,
  productboardPortalSecret: '#{productboardPortalSecret}',
  productboardPortalKey: '#{productboardPortalKey}',
  productboardInternalPortalSecret: '#{productboardInternalPortalSecret}',
  productboardInternalPortalKey: '#{productboardInternalPortalKey}',
  obpIntegrationServiceApiEndpoint: '#{obpIntegrationServiceApiEndpoint}',
  sickPayReleaseAmountLimit: '#{sickPayReleaseAmountLimit}',
  buildNumber: '#{buildNumber}',
  googleMapsKey: '#{googleMapsKey}',
  documentCollectorPageUrl: '#{documentCollectorPageUrl}'
};
