<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="col-md-12">
      <div *ngIf="dialogData.showDeclineReasonList">
        <label>{{ selectLabel }}</label
        ><label class="asterisk">*</label>

        <ng-container *ngIf="selectMode === 'tagBox'; else select">
          <app-phx-tag-box
            [dataSource]="dialogData.declineReasonList"
            required
            valueExpr="Id"
            displayExpr="Reason"
            [maxDisplayedTags]="5"
            [searchEnabled]="true"
            (valueChanged)="onDeclineReasonsChanged($event)"
            [showSelectionControls]="dialogData.showSelectionControls"
          >
          </app-phx-tag-box>
        </ng-container>

        <ng-template #select>
          <app-phx-select-box
            required
            [items]="dialogData.declineReasonList"
            (valueChanged)="onDeclineReasonsChanged($event)"
            textField="Reason"
            valueField="Id"
          ></app-phx-select-box>
        </ng-template>
      </div>
      <label>{{ dialogData.label }}</label
      ><label
        *ngIf="isCommentRequired"
        class="asterisk"
        >*</label
      >
      <div
        class="sub-label-text"
        *ngIf="dialogData.subLabelText"
      >
        {{ dialogData.subLabelText }}
      </div>
      <dx-text-area
        [height]="100"
        [(ngModel)]="comment"
        required
        [maxLength]="maxLength"
        [phxDebounce]="500"
        (onDebounce)="updateComment($event)"
        valueChangeEvent="keyup"
        [placeholder]="placeholderProvided() ? dialogData.commentPlaceholder : ('common.phxDialogComment.commentPlaceholder' | phxTranslate : title : maxLength)"
      ></dx-text-area>
      <span
        class="help-block"
        [innerHTML]="helpBlock"
      ></span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="close()"
  >
    {{ dialogData.cancelButtonText || 'common.generic.cancel' | phxTranslate }}
  </button>
  <button
    type="button"
    class="btn btn-primary"
    [disabled]="!isValid()"
    (click)="save()"
  >
    {{ saveButtonText }}
  </button>
</div>
